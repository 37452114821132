* {
  margin: 0;
}

:root {
  --font-headers: "Swanky and Moo Moo", cursive;
  --font-text: "Albert Sans", sans-serif;
  --primary-color: #002d54;
  --secundary-color: #eef5ff;
  --third-color: #4c70ad;
  --fourth-color: #b24d7a;
  --prj-height-img: 140px;
  --profile-height-img: 200px;
  --padding-img: 10px 10px 40px 10px;
  --shadow: 5px 5px 12px #14154360;
}

.App {
  background-color: var(--secundary-color);
  font-family: var(--font-text);
  color: var(--primary-color);
  font-size: 12px;
  min-height: 100vh;
}

.App .Layout main {
  width: min(calc(100% - 40px), 1054px);
  margin: 0 auto;
}
.App ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.App a {
  text-decoration: none;
  color: var(--fourth-color);
  transition: all 150ms ease-in-out;
}
.App a:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.App h1,
h2,
h3,
h4 {
  font-family: var(--font-headers);
}

.App h2 {
  font-size: 2.3em;
  line-height: 0.6em;
}
.App .title-img {
  position: relative;
  top: -1.1em;
  left: 0;
  text-align: center;
  color: var(--fourth-color);
}
.App h3 {
  font-size: 1.8em;
  line-height: 1.8em;
}
.App h4,
.App p {
  font-size: 1.1em;
  line-height: 1.5em;
}

.App .flex-row {
  display: flex;
  flex-direction: row;
}
.App .flex-column {
  display: flex;
  flex-direction: column;
}
.App .wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
}
.App .grid-content {
  grid-column: 1/ 4;
  grid-row: auto;
}

.App .footer {
  position: sticky;
  top: 100vh;
  font-size: 0.7em;
  padding: 0.3rem 0.6rem;
  text-align: center;
  background-color: var(--secundary-color);
  color: var(--primary-color);
  border-top: solid 1px var(--fourth-color);
}
.App .footer a {
  color: var(--fourth-color);
}
/* --- Dark mode changes --- */
#myApp.dark {
  --primary-color: #eef5ff;
  --secundary-color: #002d54;
  --third-color: #9abefc;
  --fifth-color: #d58bac;
  --shadow: 3px 3px 10px #d58bac15;
}
#myApp.dark h3,
#myApp.dark h1 {
  color: var(--fifth-color);
  font-weight: 500;
}

.App .dark-icon {
  display: none;
}
#myApp.dark .dark-icon {
  display: block;
}
#myApp.dark .light-icon {
  display: none;
}
#myApp.dark .footer {
  border-top: solid 1px var(--fifth-color);
}
#myApp.dark .footer a {
  color: var(--fifth-color);
}
/* ------- Responsive -------- */

@media (min-width: 450px) {
  .App {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  :root {
    --prj-height-img: 230px;
    --profile-height-img: 330px;
    --padding-img: 18px 18px 58px 18px;
  }
  .App .wrapper {
    grid-template-columns: repeat(6, 1fr);
  }
  .App {
    font-size: 16px;
  }
  .App .title-img {
    top: -1.2em;
  }
}
