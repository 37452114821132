.Contacts {
  margin: 10px 20px 20px;
}
.Contacts h1 {
  margin-bottom: 1rem;
}
.Contacts .SocialSection .social-footer {
  max-width: 400px;
  margin-bottom: 1rem;
}
.Contacts .SocialSection .social-footer .gr-icon {
  margin-top: 2rem;
}
@media (min-width: 400px) {
  .Contacts {
    max-width: 600px;
    margin: 3rem auto;
  }
  .Contacts .social-footer {
    display: flex;
    flex-direction: row;
    margin-top: 1.8rem;
  }
}
