.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 100, "GRAD" 0, "opsz" 20;
  color: var(--third-color);
}
.SubText {
  display: flex;
}
.left {
  flex-direction: row;
  align-items: baseline;
}
.right {
  flex-direction: row-reverse;
  align-items: baseline;
}
.down {
  flex-direction: column-reverse;
  align-items: end;
}
.top {
  flex-direction: column;
  align-items: end;
}
.right .material-symbols-outlined {
  transform: scaleX(-1);
}
.down .material-symbols-outlined {
  transform: scaleY(-1) rotate(53deg);
}
.top .material-symbols-outlined {
  transform: rotate(53deg);
}
.SubText p {
  font-family: var(--font-headers);
  color: var(--third-color);
  font-size: 1em;
}
