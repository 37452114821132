.theme-btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  color: var(--primary-color);
  padding: 0;
  justify-content: start;
  align-items: center;
  background-color: var(--secundary-color);
  width: 38px;
  height: 20px;
  border-radius: 0.8rem;
  border: 1.5px var(--primary-color) solid;
}
.theme-btn div {
  background-color: var(--primary-color);
  width: 15px;
  height: 15px;
  margin-right: 0.2em;
  margin-left: 0.1em;
  border-radius: 1rem;
}
.dark .theme-btn {
  flex-direction: row-reverse;
  color: var(--primary-color);
  justify-content: end;
  background-color: var(--secundary-color);
  border: 1.5px var(--fifth-color) solid;
}
.dark .theme-btn div {
  background-color: var(--fifth-color);
  margin-right: 0.1em;
  margin-left: 0.2em;
}
@media (max-width: 450px) {
  .theme-btn {
    width: 36px;
    height: 18px;
  }
  .theme-btn div {
    width: 13px;
    height: 13px;
  }
}
