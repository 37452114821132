.ImgStyle {
  border-radius: 2px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.portrait {
  padding: var(--padding-img);
  background-color: #f8f8fe;
  box-shadow: var(--shadow);
}

.profile-img {
  height: var(--profile-height-img);
}
.prj-img {
  height: var(--prj-height-img);
}
.footer-icon {
  width: auto;
  height: 26px;
}
.contact-icon {
  width: auto;
  height: 32px;
}
@media (max-width: 400px) {
  .footer-icon {
    height: 18px;
  }
}
@media (min-width: 992px) {
  .contact-icon {
    height: 40px;
  }
}
