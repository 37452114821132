.Navbar {
  position: sticky;
  top: 0;
  padding: 12px 20px;
  background-color: var(--secundary-color);
  margin-bottom: 16px;
  z-index: 1;
}
.Navbar .nav-title {
  font-size: 1.1em;
  font-weight: 600;
  text-transform: lowercase;
  color: var(--third-color);
}
.Navbar .nav-title:hover {
  text-decoration: none;
  opacity: 1;
}
.Navbar .menu-icon {
  position: absolute;
  top: 12px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.2em;
  width: 1.6em;
  cursor: pointer;
}
.Navbar .menu-icon span {
  background-color: var(--third-color);
  height: 2.8px;
  width: 100%;
  border-radius: 2px;
}
.Navbar .menu-icon-cl {
  z-index: 2;
}
.Navbar .menu-icon-cl span {
  background-color: var(--secundary-color);
}

.Navbar .menu-icon-cl span:first-child {
  display: none;
}
.Navbar .menu-icon-cl span:nth-child(2) {
  transform: translate(0, 225%) rotate(45deg);
}
.Navbar .menu-icon-cl span:last-child {
  transform: translate(0, -225%) rotate(-45deg);
}
.Navbar .menu-close {
  display: none;
}
.Navbar .menu-group {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1415435d;
  height: 100vh;
  width: 100%;
}
#myApp.dark .Navbar .menu-group {
  background-color: #7c9dd5ab;
}
.Navbar .menu-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(12deg);
  background-color: var(--secundary-color);
  box-shadow: var(--shadow);
  width: 260px;
  height: 260px;
}
.Navbar .menu-group ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  transform: rotate(-12deg);
  line-height: 5em;
}

.Navbar .menu-link a {
  font-family: var(--font-headers);
  font-size: 2.3em;
  color: var(--primary-color);
}
.Navbar .menu-link .active {
  color: var(--fourth-color);
}
.Navbar .menu-link a:hover {
  color: var(--fourth-color);
  opacity: 1;
}

.Navbar .menu-link .active:hover {
  opacity: 1;
  text-decoration: none;
}
#myApp.dark .Navbar .menu-link a {
  color: var(--primary-color);
}
#myApp.dark .Navbar .menu-link .active,
#myApp.dark .Navbar .menu-link a:hover {
  color: var(--fifth-color);
}
@media (min-width: 450px) {
  .Navbar .menu-bg {
    width: 340px;
    height: 340px;
  }
}
@media (min-width: 768px) {
  .Navbar {
    padding: 20px 46px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  .Navbar .menu-close {
    display: block;
  }
  .Navbar .menu-icon {
    display: none;
  }
  .Navbar .menu-group {
    position: relative;
    background-color: unset;
    height: unset;
    width: unset;
  }
  #myApp.dark .Navbar .menu-group {
    background-color: unset;
  }
  .Navbar .menu-bg {
    position: unset;
    transform: unset;
    box-shadow: none;
    width: unset;
    height: unset;
  }
  .Navbar .menu-group ul {
    display: flex;
    flex-direction: row;
    transform: none;
    line-height: 1em;
  }
  .Navbar .menu-link a {
    font-family: var(--font-text);
    margin-right: 2.5rem;
    font-size: 1.1em;
    color: var(--third-color);
  }
  .Navbar .menu-link .active {
    font-weight: 600;
  }
  .Navbar .menu-link a:hover {
    color: var(--fourth-color);
    opacity: 1;
  }
  .Navbar .menu-link .active,
  .Navbar .menu-link .active:hover {
    color: var(--third-color);
  }
  #myApp.dark .Navbar .menu-link a {
    color: var(--fifth-color);
  }
  #myApp.dark .Navbar .menu-link a:hover {
    color: var(--third-color);
  }
  #myApp.dark .Navbar .menu-link .active,
  #myApp.dark .Navbar .menu-link .active:hover {
    color: var(--fifth-color);
  }
}
