.Projects {
  margin: 10px 0px 20px;
}
.Projects .wrapper {
  gap: 1.8rem 1.3rem;
}
.Projects h1 {
  margin-bottom: 1.8em;
}
.Projects .ProjSection {
  margin-bottom: 3rem;
}
.Projects .group-grid {
  grid-column: 1/ 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 1rem;
  grid-template-rows: repeat(3, 1fr);
  max-height: calc(var(--prj-height-img) + 54px);
}
.Projects .group-grid .SubText {
  grid-column: 1/2;
  grid-row: 2 / 3;
  flex-wrap: wrap;
  text-align: right;
  align-items: end;
}
.Projects .grid-img {
  grid-column: 2/ 5;
  grid-row: 1 / 4;
  max-height: calc(var(--prj-height-img) + 54px);
  position: relative;
}

.Projects .grid-img:hover {
  transform: rotate(-6deg);
  opacity: 1;
}

.Projects h4 {
  font-family: var(--font-text);
  line-height: 1.3rem;
  text-transform: capitalize;
}

.Projects .social-footer {
  display: flex;
  flex-direction: row;
}
@media (max-width: 280px) {
  :root {
    --prj-height-img: 100px;
  }
  .Projects .group-grid {
    max-height: calc(var(--prj-height-img) + 54px + 1.8rem);
  }
  .Projects .group-grid .SubText {
    grid-column: 1/ 5;
    grid-row: 3 / 4;
    text-align: left;
    justify-content: start;
    flex-direction: row-reverse;
  }
  .down .material-symbols-outlined {
    transform: scaleX(-1) rotate(-53deg);
  }
  .Projects .grid-img {
    grid-column: 1/ 5;
    grid-row: 1 / 3;
  }
}
@media (min-width: 400px) {
  .Projects {
    padding: 0 40px;
  }
}
@media (max-width: 992px) {
  .Projects {
    max-width: 390px;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .Projects .wrapper {
    gap: 3rem 5rem;
  }
  .Projects .group-grid {
    grid-column: 1/ 4;
  }
  .Projects .grid-content {
    margin-top: calc(var(--prj-height-img) / 2);
    grid-column: 4/ 7;
    grid-row: 1/ 3;
  }
}
