.SocialSection {
  align-items: center;
  margin-bottom: 2em;
}
.SocialSection .social-footer {
  width: 100%;
  max-width: 300px;
  justify-content: space-between;
}
.SocialSection .social-footer .gr-icon {
  align-items: center;
}
.SocialSection a {
  transition: all 120ms ease-in-out;
}
.SocialSection .social-footer .gr-icon a:hover {
  transform: rotate(16deg);
}
.SocialSection .social-footer .gr-icon:nth-child(2) a:hover {
  transform: rotate(-16deg);
}
.SocialSection .email-link {
  margin-top: 1.6em;
  font-family: var(--font-headers);
  font-size: 1.1em;
  color: var(--primary-color);
}

.SocialSection .social-footer .gr-icon {
  margin-top: 20px;
}

@media (max-width: 400px) {
  .SocialSection .social-footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .SocialSection .social-footer .gr-icon {
    margin: 0.6rem 0.9rem;
  }
}
