.About {
  margin: 20px 0px;
}
.About .wrapper {
  gap: 0 1rem;
}
.About .Header {
  grid-column: 1/ 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  grid-template-rows: repeat(4, 1fr);
  max-height: calc(var(--profile-height-img) + 54px);
}
.About .Header .grid-img {
  grid-row: 1 / 5;
  grid-column: 2/ 5;
}
.About .Header .SubText {
  grid-column: 1/ 2;
  flex-wrap: wrap;
  justify-content: start;
  text-align: right;
  align-items: end;
}
.About .Header .top p {
  margin-right: 1rem;
}
.About .grid-content h3 {
  margin-top: 1.2em;
}
.About .list-style {
  margin-top: 0.4em;

  line-height: normal;
}
.About .list-style li {
  margin-bottom: 0.5em;
}
.About .list-style strong {
  font-weight: 600;
}
.About .list-style div {
  margin-left: 3rem;
}
.About .list-style .ListPath {
  margin-bottom: 1.5em;
}
.About .path-date {
  font-weight: 100;
  margin-bottom: 0.4em;
}
.About .path-degree {
  font-weight: 500;
}
.About .path-place {
  color: var(--third-color);
  font-size: 0.9em;
}
.About .SocialSection {
  margin-top: 3rem;
}
.About .Skills {
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 70%;
}

.About .Skills .ImgStyle {
  margin: 0.5rem 1.3rem;
}

.About .Skills i {
  font-size: 2em;
  margin: 0.8rem 1.3rem;
}
.About .social-footer {
  display: flex;
  flex-direction: row;
}
@media (max-width: 280px) {
  .About .Header {
    display: block;
  }
  .About .Header .SubText {
    display: none;
  }
}
@media (max-width: 300px) {
  .About .Skills {
    width: 100%;
  }
}
@media (min-width: 400px) {
  .About {
    padding: 0 40px;
  }
}

@media (max-width: 992px) {
  .About {
    max-width: 425px;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .About .wrapper {
    gap: 0rem 5rem;
  }
  .About .Header {
    grid-column: 1/ 4;
    position: sticky;
    margin-top: 20px;
    top: 80px;
  }
  .About .grid-content {
    margin-top: calc(var(--profile-height-img) / 2);
    grid-column: 4/ 7;
    grid-row: 1/ 3;
  }

  .About .SocialSection {
    margin-top: 3em;
  }
}
